// import React from "react";
// import { Link } from "react-router-dom";
// import blogs from "./blogsData";
// import "./CSS/BlogPage.css";

// const BlogPage = () => {
//   return (
//     <div className="blog-container">
//       {blogs.map((blog) => (
//         <div key={blog.id} className="blog-card">
//           <div className="blog-image-wrapper">
//             <img src={blog.image} alt={blog.title} className="blog-image" />
//           </div>
//           <div className="blog-content">
//             <h2 className="blog-title">{blog.title}</h2>

//             {/* <p className="blog-description">{blog.description}</p> */}
//             <div className="link-box">
//               <Link to={`/blog/${blog.id}`} className="blog-read-more">
//                 Read More...
//               </Link>

//               <a
//                 href={blog.product_link}
//                 className="blog-product-link"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 Buy
//               </a>
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default BlogPage;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import BlogsSkeleton from "../../Skeletons/Blogs-Skeleton";

const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchBlogs = async () => {
      try {
        const response = await fetch(
          "https://jaipurmasale-backend.onrender.com/api/get-all-blogs/"
        );
        const data = await response.json();
        setBlogs(data.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const placeholderImage =
    "https://imgs.search.brave.com/Tn5pKlMOw0_FerWffTprFCqRcdROmyyfH62WlFnrX-A/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly91cGxv/YWQud2lraW1lZGlh/Lm9yZy93aWtpcGVk/aWEvY29tbW9ucy9h/L2FjL05vX2ltYWdl/X2F2YWlsYWJsZS5z/dmc";

  if (loading) return <BlogsSkeleton />;

  return (
    <div className="max-w-7xl mx-auto px-4 py-6 mt-6 sm:mt-12">
      <Helmet>
        <link rel="canonical" href="https://www.jaipurmasale.com/Blog" />
      </Helmet>
      <h1 className="text-3xl font-bold text-center mb-6">Latest Blogs</h1>

      {/* Flexbox container */}
      <div className="flex flex-wrap justify-between gap-6  sm:px-10">
        {blogs.map((blog) => (
          <div
            key={blog.id}
            className="bg-white border-[#cc044f] border-[1px]  shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105 flex flex-col w-full sm:w-[50%] md:w-[45%] lg:w-[30%] h-[300px] sm:min-h-[450px]"
          >
            {/* Image */}
            <img
              src={blog.image || placeholderImage}
              alt={blog.title}
              className="w-full h-40 sm:h-60 object-contain"
              loading="lazy"
              onError={(e) => (e.target.src = placeholderImage)}
            />

            {/* Content */}
            <div className="p-4 flex flex-col flex-grow">
              <h2 className="text-xl font-semibold mb-2 truncate ">{blog.title}</h2>
              <p className="text-gray-600 text-sm flex-grow line-clamp-3">
                {blog.description || "Explore our latest updates and recipes."}
              </p>

              {/* Footer */}
              <div className="flex justify-between items-center mt-4">
                <Link
                  to={`/blog/${blog.id}`}
                  className="text-blue-500 hover:underline"
                >
                  Read More
                </Link>
                {blog.product_link && (
                  <a
                    href={blog.product_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                  >
                    Buy
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogPage;

