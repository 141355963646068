import React, { useContext, useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ShopContext } from "../../Context/ShopContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { FaSearch } from "react-icons/fa";
import Overlay from "../Overlay";
import "./Navbar.css";
import cart_icon from "../Assets/cart_icon.webp";
import nav_dropdown from "../Assets/nav_dropdown.webp";
import jaipur_masale from "../Assets/jaipur_masale.webp";

//  all navlinks
const navLinks = [
  // { name: "Home", path: "/" },
  { name: "Khada Masala", path: "/organic-khada-masala" },
  { name: "Powdered Masala", path: "/organic-powdered-masala" },
  { name: "Dry Fruits & Berries", path: "/premium-dry-fruits-berries" },
  { name: "Best Deals", path: "/organic-khada-masala" },
  { name: "Blog", path: "/blog" },
  { name: "Contact Us", path: "/contact-us" },
];

const Navbar = ({ isHomePage }) => {
  const [menu, setMenu] = useState("shop");
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token")
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { clearCartItems, getTotalCartItems } = useContext(ShopContext);
  const menuRef = useRef();
  const imageRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const handleStorageChange = () =>
      setIsAuthenticated(!!localStorage.getItem("token"));
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    clearCartItems();
    navigate("/");
  };

  const dropdownToggle = (e) => {
    menuRef.current.classList.toggle("nav-menu-visible");
    e.target.classList.toggle("open");
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY <= 100) {
        setDropdownOpen(false);
        menuRef.current.classList.remove("nav-menu-visible");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleSearchChange = (e) => setSearchQuery(e.target.value);
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?query=${searchQuery}`);
  };

  return (
    <div>
      <div className={`navbar ${isHomePage ? "home" : "not-home"}  bg-white `}>
        {/*  */}
        <div className="navbar-horizontal justify-end">
          <Link to="/" onClick={() => setMenu("shop")} className="nav-logo">
            <img src={jaipur_masale} alt="Logo" />
          </Link>

          <div className="nav-login-cart flex flex-row justify-end  items-center gap-7 max-w-[220px] w-full  sm:max-w-full">
            <form
              onSubmit={handleSearchSubmit}
              className="nav-search-form-desktop  w-9/12 "
            >
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="outline-none  text-lg px-3 py-2 border-gray-300 rounded-lg min-h-9 w-full"
              />
              <button type="submit" className="nav-search-button p-2 ">
                <FaSearch size={20} />
              </button>
            </form>

            {!isAuthenticated ? (
              <Link to="/Login">
                <button className="w-28 h-10 outline-none border border-[#e1d6d6]  rounded-full text-[#515151] text-lg font-medium bg-white cursor-pointer transition-all duration-300 ease-in-out hover:bg-gradient-to-r hover:from-[#76c329] hover:via-[#b1256b] hover:to-[#c0056c] hover:text-white hover:border-transparent sm:w-32 sm:h-12 sm:text-xl">
                  Login
                </button>
              </Link>
            ) : (
              <div className="profile-dropdown">
                <FontAwesomeIcon
                  icon={faUser}
                  style={{ color: "#000", fontSize: "24px" }}
                />
                <div className="dropdown-content">
                  <button onClick={handleLogout}>Logout</button>
                </div>
              </div>
            )}
            <Link to="/cart">
              <img src={cart_icon} alt="Cart" />
            </Link>
            <div className="nav-cart-count">{getTotalCartItems()}</div>

            <img
              onClick={dropdownToggle}
              className="nav-dropdown"
              src={nav_dropdown}
              alt="Menu"
              ref={imageRef}
            />
          </div>
        </div>

        {/* mobile devices */}
        <form onSubmit={handleSearchSubmit} className="nav-search-form  p-1">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="nav-search-input"
          />
          <button type="submit" className=" border-[1px] p-2 rounded-full ">
            <FaSearch size={25} />
          </button>
        </form>
      </div>

      {/* Bottom Nav for navlinks */}
      <ul
        ref={menuRef}
        className=" w-full bg-gray-300 sm:bg-white hidden sm:flex flex-col sm:flex-row justify-center items-center list-none gap-4 sm:gap-10 font-medium pt-3 m-0"
      >
        {navLinks.map(({ name, path }) => (
          <li
            key={name}
            className={` ${
              menu === name ? "bg-[#cc044f] text-white border-t-gray-300" : "text-black  "
            } border-[#cc044f] border-[1px]  hover:bg-[#cc044f] hover:text-white flex   flex-col items-center justify-center gap-1 text-sm cursor-pointer transition-colors duration-300  p-2 rounded-full w-40 truncate`}
            onClick={() => {
              setMenu(name);
              setDropdownOpen(false);
              menuRef.current.classList.remove("nav-menu-visible");
              imageRef?.current?.classList?.remove("open");
            }}
          >
            <Link to={path} className="no-underline text-inherit">
              {name}
            </Link>
            {/* {menu === name && (
              <hr className="border-none w-4/5 h-1 rounded-md bg-green-500" />
            )} */}
          </li>
        ))}
      </ul>

      {/* {dropdownOpen && <Overlay open={dropdownOpen} />} */}
    </div>
  );
};

export default Navbar;
