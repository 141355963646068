import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Shop from "./Pages/Shop";
import ShopCategory from "./Pages/ShopCategory";
import Product from "./Pages/Product";
import Cart from "./Pages/Cart";
import Signup from "./Pages/Signup";
import Login from "./Components/Login/Login";
// import Footer from './Components/Footer/Footer';
import powder_masala_banner from "./Components/Assets/powder_masala.webp";
import khada_masala_banner from "./Components/Assets/khada_masala.webp";
import dry_fruits_banner from "./Components/Assets/dry_fruits.webp";
import BlogDetail from "./Pages/Blog/BlogDetail";
import BlogPage from "./Pages/Blog/BlogPage";
import AddItems from "./Pages/AddItems";
import ScrollingUp from "./Components/ScrollingUp/ScrollingUp";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import ContactUs from "./Components/ContactUs/ContactUs";
import Query from "./Components/Query/Query";
import AboutUs from "./Components/AboutUs/AboutUs";
import CustomerReviews from "./Components/CustomerReviews/CustomerReviews";
import WhatsAppButton from "./Components/WhatsAppButton/WhatsAppButton";
import Footer2 from "./Components/Footer2/Footer2.jsx";
import SearchResults from "./Components/SearchResults/SearchResults.jsx";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy.jsx";
import RefundPolicy from "./Components/RefundPolicy/RefundPolicy.jsx";
import ShippingPolicy from "./Components/ShippingPolicy/ShippingPolicy.jsx";
import NotFound from "./Components/NotFound/NotFound";
import { Suspense, lazy } from "react";
import ScrollerSkeleton from "./Skeletons/Scroller-Skeleton.jsx";

// Lazy loading components
const Scroller = lazy(() => import("./Components/Scroller/Scroller"));

const Layout = ({ children }) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <>
      {isHomePage && (
        <Suspense fallback={<ScrollerSkeleton />}>
          {/* Loading indicator */}
          <Scroller />
        </Suspense>
      )}
      <Navbar isHomePage={isHomePage} />
      {children}
    </>
  );
};

function App() {
  return (
    <div>
      <BrowserRouter>
        <Layout>
          <ScrollingUp />
          <Routes>
            <Route path="/" element={<Shop />} />
            <Route
              path="/organic-khada-masala"
              element={
                <ShopCategory banner={khada_masala_banner} category_id={1} />
              }
            />
            <Route
              path="/organic-powdered-masala"
              element={
                <ShopCategory banner={powder_masala_banner} category_id={2} />
              }
            />
            <Route
              path="/premium-dry-fruits-berries"
              element={
                <ShopCategory banner={dry_fruits_banner} category_id={3} />
              }
            />
            <Route path="/add_items" element={<AddItems />} />
            <Route path="/product/:productId" element={<Product />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/query" element={<Query />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/customer-reviews" element={<CustomerReviews />} />
            <Route path="/blog/:id" element={<BlogDetail />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/Signup" element={<Signup />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/search" element={<SearchResults />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/refund" element={<RefundPolicy />} />
            <Route path="/shipping" element={<ShippingPolicy />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer2 />
          <WhatsAppButton />
        </Layout>
        <Analytics />
        <SpeedInsights />
      </BrowserRouter>
    </div>
  );
}

export default App;
