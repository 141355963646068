const SkeletonLoader = () => {
  return (
    <div className=" max-w-7xl  m-auto flex flex-col justify-between gap-4 p-5 sm:p-4 mt-10 sm:mt-14  ">
      <div className="h-10 m-auto bg-gray-200  w-24 sm:w-40 rounded"></div>
      <div className="flex flex-row flex-wrap gap-5 sm:gap-2 justify-between ">
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            className="flex flex-col justify-between  p-6 rounded-lg border-2 shadow-md animate-pulse bg-white w-full  sm:w-[48%] lg:w-[30%]   h-[300px] sm:min-h-[500px]"
          >
            {/* Image Skeleton */}
            <div className="w-full h-full bg-gray-200 rounded"></div>

            {/* Title Skeleton */}
            <div className="mt-4 h-5 bg-gray-300 w-3/4 rounded"></div>

            {/* Buttons Skeleton */}
            <div className="flex gap-5 items-center mt-4">
              <div className="h-10 bg-gray-200 w-24 rounded"></div>
              <div className="h-10 bg-gray-200 w-24 rounded"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkeletonLoader;
