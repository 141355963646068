import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./CSS/BlogDetail.css";

const BlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlogDetail = async () => {
      try {
        const response = await fetch(
          `https://jaipurmasale-backend.onrender.com/api/get-blog-detail/?blog_pk=${id}`
        );
        const data = await response.json();
        setBlog(data.data);
      } catch (error) {
        console.error("Error fetching blog detail:", error);
      }
    };

    fetchBlogDetail();
  }, [id]);

  if (!blog) {
    return <div> Blog is Loading...</div>;
  }

  return (
    <div className="blog-detail-container">
      <Helmet>
        <title>{blog.title} | Jaipur Masale</title>
        <link rel="canonical" href={`https://jaipurmasale.com/blog/${id}`} />
      </Helmet>
      <div className="blog-detail-image-wrapper">
        <img src={blog.image} alt={blog.title} className="blog-detail-image" />
      </div>
      <h1 className="blog-detail-title">{blog.title}</h1>
      <a
        href={blog.product_link}
        className="blog-product-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Buy
      </a>
      <div
        className="blog-detail-content"
        dangerouslySetInnerHTML={{ __html: blog.description }}
      />
    </div>
  );
};

export default BlogDetail;
